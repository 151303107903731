* {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  font-weight: 400;
}

body {
  display: block;
  background-color: #FFF8DC;
}

.maincontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
}

.top-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 16px;
  width: 100%;
  margin-bottom: 8px;
  justify-content: flex-start;
  margin-top: 16px;
}

/* Logo */
.logo a {
  font-style: normal;
  font-weight: 704;
  font-size: 20px;
  line-height: 24px;
  font-variant: small-caps;
  color: #000000;
  padding-left: 16px;
}

.button-animation {
  -webkit-transition: -webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: -webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  -o-transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7),-webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
}

.mailinglist-button {
  background-color: #2927A6;
  color: white;
  border: none;
  border-radius: 16px;
  border: 2px solid #000000;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 800;
  margin-left: auto;
  margin-bottom: 16px;
  margin-right: 48px;
}

.mailinglist-button:hover {
  box-shadow: 4px 4px black;
}

.beta-label {
  display: inline-block;
  padding: 0px 10px;
  margin-left: 4px;
  background: #2927A6;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  font-variant: all-small-caps;
  padding-bottom: 1.5px;
}

.accordion-container {
  flex-grow: 1; /* Allows the accordion-container to grow and occupy the remaining space */
  margin-top: 48px;
  height: 500px;
  overflow: auto;
  padding-right: 16px;
  /* margin-right: 0px !important; */
  /* display: flex;
  flex-direction: column-reverse; */
}

a:link {
  color: #2927A6;
  text-decoration-style: dotted;
}

.export-google-maps-container {
  display: flex;
  justify-content: center;
}

.export-google-maps {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  color: #2927A6;
  text-decoration-style: dotted;
  text-decoration-line: underline;
}

.arrow-icon {
  display: inline-block;
  float: right;
}

.accordion-container .card {
  margin-bottom: 20px;
  border-radius: 0px;
  border: 2px solid #222;
  border-bottom: none;
}

.accordion-container .card .card-header {
  border-radius: 0px;
  border-bottom: 2px solid #222;
  background-color: #FFCC00;
  font-variant: small-caps;
  font-weight: 600;
  font-size: 17px;
}

.accordion-container .loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.loading-spinner {
  margin-bottom: 8px;
}

.accordion-container .spinner-grow {
  margin-left: 8px;
}

.day-modify, .day-modify a {
  display: flex;
  align-items: center;
}

.day-modify span {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 22px;
  color: #2927A6;
  padding-left:4px;
  /* or 183% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  text-transform: capitalize;
}

.accordion-container .card-body.show {
  border-bottom: 2px solid black;
}

.no-transition {
  transition: none !important;
}

/* chatbot styles */
.chatbot, .accordion-container, .travel-map-container {
  margin: 24px;
}

.chatbot, .travel-map-container {
  flex: 1;
  max-width: 366px; /* Set the maximum width for the chatbot container */ 
  margin-right: 0px;
  padding-right: 16px;
}

.travel-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rsc-container {
  background-color: #FFFFFF;
  position: relative;
  border: 2px solid #222;
  top: initial !important; /* important to override media query from chatbot library*/
  left: initial !important; /* important to override media query from chatbot library*/
  border-radius: 0px;
  box-shadow: 0px 0px black;
  min-width: 350px !important;
  width: 100% !important; /* important to override media query from chatbot library*/
  height: 100%;
}

.rsc-ts-bot {
  margin-top: 20px;
  margin-left: 12px;
}

/* Chatbot messages */
.rsc-ts-bubble, .rsc-os-option-element {
  background-color: #9493D3;
  position: relative;
  top: -3px;
  left: 6px;
  border: 2px solid #222;
  border-radius: 18px;
  color: black;
}

.rsc-ts-bot .rsc-ts-bubble {
  box-shadow: -4px 4px black;
}

.rsc-os-option-element {
  background-color: #2927A6;
  color: white !important;
}

.rsc-os-option-element {
  -webkit-transition: -webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: -webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  -o-transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
  transition: transform .3s cubic-bezier(.5,2.5,.7,.7),box-shadow .3s cubic-bezier(.5,2.5,.7,.7),-webkit-transform .3s cubic-bezier(.5,2.5,.7,.7),-webkit-box-shadow .3s cubic-bezier(.5,2.5,.7,.7);
}

.rsc-os-option-element:hover {
  box-shadow: -4px 4px black;
}


.rsc-os {
  margin-left: 12px;
}

.rsc-os-option {
  margin-right: 8px;
}

/* User messages */
.rsc-ts-user .rsc-ts-bubble {
  background-color: #2927A6;
  color: #FFFFFF !important;
  box-shadow: 4px 4px black;
}

.rsc-ts-user {
  margin-right: 32px;
}

/* Custom bot message for terms and conditions */
.consent {
  display: 'inline-block';
  border-radius: '10px';
  padding: '10px';
  font-size: '15px';
  font-weight: '300';
  line-height: '25px';
  background-color: '#f5f8fb';
  color: '#000';
}

/* Text input */
.rsc-footer {
  background-color: #FFCC00;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: 2px solid #000;
}

/* Text input */
.rsc-input {
  width: 80%;
  height: 40px;
  border-radius: 18px;
  border: 2px solid #000;
}

.rsc-input:disabled {
  background-color: #E6E3ED;
  opacity: 0.7;
}

.rsc-header {
  display: none;
}

.rsc-ts-image-container {
  display: none;
}

.rsc-ts-bubble {
  margin-left: 10px;
}

.rsc-ts-bubble a {
  color: #222088;
}

.rsc-content {
  margin-top: 0px;
  padding-bottom: 12px;
}

.rsc-footer button {
  right: calc(15%);
  top: 17px;

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.rsc-footer button:before {
  content: "✈";
  color: #2927A6;
}

.rsc-footer button svg {
  display: none
}

/* Media query for tablet breakpoint */
@media (max-width: 768px) {
  .top-container {
    flex-direction: column;
  }

  .chatbot, .accordion-container, .travel-map-container {
    margin: 8px;
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .chatbot, .travel-map-container {
    max-width: initial !important;
  }

  .accordion-container {
    height: auto;
    min-height: calc(100vh - 230px);
  }
}

/* card container styling */
.outer-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.scroll-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  position: relative;
  margin-right: 24px;
  margin-left: 24px;
}

.cards-container {
  display: flex;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 0px;
  padding-bottom: 16px;
}

/* card styling */
.card-container {
  background-color: #9493D3;
  border-radius: 18px;
  border: 2px solid #000000;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 240px;
  height: 140px;
  overflow: hidden; /* Hide any overflowing content */
}

.card-container:hover {
  box-shadow: 6px 6px black;
}

.image-container {
  height: 83px;
  width: 100%;
  overflow: hidden;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  padding: 8px;
  flex-grow: 1;
}

.card-title {
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap; /* Prevents line breaks and wraps */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Displays an ellipsis when text overflows */
  max-width: 100%; /* Ensures the text doesn't push other content */
}

.rating-container {
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.rating-container img {
  padding-right: 2px;
}

.card-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 16px;
}

.day-label-container, .sample-itinerary-header {
  height: 24px; /* Adjust the height to match the height of the city label */
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.day-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
}

/* Media query for tablet breakpoint */
@media (max-width: 768px) {
  .cards-container {
    margin-left: 0px;
  }
}

.arrow {
  position: absolute;
  top: 40%;
  z-index: 1;
  cursor: pointer;
}

.left-arrow {
  left: 2px;
}

.right-arrow {
  right: 2px;
}

.modal .modal-content {
  width: 100%;
  max-width: 488px;
  height: 304px;
  background-color: #ffffff; /* white background */
  border: 2px solid #222;
  border-radius: 0; /* Remove border radius */
  box-shadow: 4px 4px black;
}

.modal .modal-top {
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFCC00; /* yellow background */
  border-bottom: 2px solid #222;
  font-variant: small-caps;
  font-weight: 600;
  font-size: 17px; /* Font size 17px */
  padding-left: 60px;
  padding-right: 30px;
  line-height: 17px;
}

.modal .modal-bottom {
  height: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
}

.modal .modal-bottom .modal-close {
  border: 2px solid #222;
  border-radius: 50%; /* Circle around X */
}

.modal .modal-bottom .form-control {
  width: 360px;
  height: 56px; /* Change height to 56px */
  border: 2px solid #222; /* Add black border */
  border-radius: 16px;
  padding: 10px;
  margin-bottom:8px;
}

.modal .modal-header {
  border-top-left-radius: 0; /* remove top left radius */
  border-top-right-radius: 0; /* remove top right radius */
}


.modal .modal-bottom .signup-btn {
  margin-top: 10px;
  padding: 10px;
  background-color: #2927A6; /* blue background */
  color: white;
  border: none;
  border-radius: 12px; /* Increase border radius */
  cursor: pointer;
  font-weight: 400;
  
  border: 2px solid #000000;
}

.modal .modal-bottom .signup-btn:hover {
  background-color: darkblue;
  box-shadow: 4px 4px black;
}

.modal .modal-title {
  font-size: 17px; /* set font size to 17px */
}

.accordion-section {
  position: relative;
  display: flex;
  flex-grow: 1;
  /* other styles you need for this section */
}

.save-button-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 24px; /* aligns the button to the right */
  top: -16px; /* adjust this value based on your button's height */
}

/* Media query for tablet breakpoint */
@media (max-width: 768px) {
  .save-button-container {
    top: -26px;
  }
}

.save-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  justify-content: flex-end; /* Aligns the button to the right */
}

.save-button svg {
  margin-right: 4px;
}

.save-button span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
}

.save-icon {
  margin-right: 8px;
}

.save-button.loading {
  color: #7C6E74;
}

.save-button.loading .save-icon {
  fill: currentColor;
}

.copy-link-button {
  background-color: #2927A6;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 17px;
  border: 2px solid #000000;
  margin-top: 16px;
}
.copy-link-button:hover {
  background-color: #1d1b85;
  box-shadow: 4px 4px black;
}

.social-links button {
  margin: 16px;
  margin-top: 32px;
}

.success-subscribed {
  margin-top: 56px;
}

.suggested .card-body {
  margin-left: 16px;
  margin-right: 16px;
}

.suggested .card {
  border-bottom: 2px solid #222;
  margin-bottom: 0px;
  height: 100%;
}

.suggested .card:hover {
  box-shadow: 0px 0px black;
}

.suggested button {
  display: block;
  width: 100%;
  height: 44px;
  margin-bottom: 18px;
  background-color: #2927A6; /* blue background */
  color: white;
  border-radius: 16px; /* Increase border radius */
  cursor: pointer;
  font-weight: 800;
  border: 2px solid #000000;
  font-size: 12px;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  position: relative;
  padding-right: 32px;
  padding-left: 32px;
}

.suggested button:hover {
  box-shadow: 4px 4px black;
}


.suggested button span {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 16px;
}